import React from "react";

import OcorrenciaTemplate from "~/Components/Negocios/CaixaAprovacao/OcorrenciaTemplate";
import RateioTemplate from "~/Components/Negocios/CaixaAprovacao/RateioTemplate";
import RetencaoTemplate from "~/Components/Negocios/CaixaAprovacao/RetencaoTemplate";
import TextoTemplate from "~/Components/Negocios/CaixaAprovacao/TextoTemplate";

export const orderingOptions = [
  {
    label: "Vencimento (crescente)",
    value: "vencimento-crescente",
    func: (c1, c2) => (c1.data_vencimento < c2.data_vencimento ? -1 : 1),
  },
  {
    label: "Vencimento (decrescente)",
    value: "vencimento-decrescente",
    func: (c1, c2) => (c1.data_vencimento < c2.data_vencimento ? 1 : -1),
  },
  {
    label: "Valor (crescente)",
    value: "valor-crescente",
    func: (c1, c2) => (c1.valor < c2.valor ? -1 : 1),
  },
  {
    label: "Valor (decrescente)",
    value: "valor-decrescente",
    func: (c1, c2) => (c1.valor < c2.valor ? 1 : -1),
  },
];

export const blankView = {
  title: "",
  chave: {},
  content: null,
  render: () => {},
};

export const modalViews = {
  ocorrencias: {
    title: "Ocorrências",
    chave: null,
    content: null,
    render: (chave) =>
      chave &&
      chave.ocorrencias.map((item, index) => (
        <OcorrenciaTemplate key={index} ocorrencia={item} />
      )),
  },
  retencoes: {
    title: "Retenções",
    chave: null,
    content: null,
    render: (chave) =>
      chave &&
      chave.retencoes.map((item, index) => (
        <RetencaoTemplate key={index} retencao={item} />
      )),
  },
  rateios: {
    title: "Rateios",
    chave: null,
    content: null,
    render: (chave) =>
      chave &&
      chave.rateios.map((item, index) => (
        <RateioTemplate key={index} rateio={item} />
      )),
  },
  digitalizacao: {
    title: "Digitalização",
    chave: null,
    content: null,
    render: (digitalizacao) =>
      digitalizacao && (
        <a href={digitalizacao.url} target="_blank" rel="noopener noreferrer">
          <img
            src={digitalizacao.Thumb}
            alt="Clique para abrir em outra aba"
            className="img-fluid"
          />
        </a>
      ),
  },
  texto: {
    title: "Texto",
    content: null,
    render: (chave) =>
        <TextoTemplate chave={chave} />
  },
};
