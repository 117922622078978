import React from "react";
import { FaUser } from "react-icons/fa";

import format from "~/Utils/format";

const TextoTemplate = ({ chave }) => (

  <div>
    <div className="d-flex justify-content-between">
      <small>{format.datetime(chave.data)}</small>
      <small className="text-right" title={chave.usuario_digitacao.nome_usuario}>
        <FaUser className="mr-1" />
        {chave.usuario_digitacao.nome}
      </small>
    </div>
    <br></br>
    <p>{chave.texto ? chave.texto : "Sem registros."}</p>
    <hr />
  </div>
);

export default TextoTemplate;
