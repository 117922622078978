import React, { useState, useEffect } from "react";
import Select from "react-select";

function FiltrosChaves({
  setFiltros,
  contas,
  usuarios,
  fornecedores,
  chaves,
  documentos
}) {
  const [contaSelecionada, setContaSelecionada] = useState(null);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
  const [documentoSelecionado, setDocumentoSelecionado] = useState(null);

  const [contasFiltradas, setContasFiltradas] = useState([]);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const [fornecedoresFiltrados, setFornecedoresFiltrados] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);

  const handleChange = (name) => (selectedOption) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const formatOptions = (options) =>
    options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

  const filtrarOpcoesValidas = () => {
    let chavesFiltradas = [...chaves];

    if (usuarioSelecionado) {
      chavesFiltradas = chavesFiltradas.filter(
        (chave) => chave.usuario_digitacao.nome === usuarioSelecionado
      );
    }

    if (contaSelecionada) {
      chavesFiltradas = chavesFiltradas.filter(
        (chave) => chave.codigo_conta_portador === contaSelecionada
      );
    }

    if (fornecedorSelecionado) {
      chavesFiltradas = chavesFiltradas.filter(
        (chave) => chave.codigo_conta_fornecedor === fornecedorSelecionado
      );
    }

    if (documentoSelecionado) {
      chavesFiltradas = chavesFiltradas.filter(
        (chave) => chave.tipo_documento === documentoSelecionado
      );
    }

    const contasValidas = [...new Map(chavesFiltradas.map((chave) => ({
      value: chave.codigo_conta_portador,
      label: chave.codigo_conta_portador + ": " + chave.conta_portador.apelido_conta,
    })).map((item) => [item.value, item])).values()];

    const usuariosValidos = [...new Map(chavesFiltradas.map((chave) => ({
      value: chave.usuario_digitacao.nome,
      label: chave.usuario_digitacao.nome,
    })).map((item) => [item.value, item])).values()];

    const fornecedoresValidos = [...new Map(chavesFiltradas.map((chave) => ({
      value: chave.codigo_conta_fornecedor,
      label: chave.codigo_conta_fornecedor + ": " + chave.fornecedor.apelido_conta,
    })).map((item) => [item.value, item])).values()];

    const documentosValidos = [...new Map(chavesFiltradas.map((chave) => ({
      value: chave.tipo_documento,
      label: chave.tipo_documento,
    })).map((item) => [item.value, item])).values()];

    setContasFiltradas(contasValidas);
    setUsuariosFiltrados(usuariosValidos);
    setFornecedoresFiltrados(fornecedoresValidos);
    setDocumentosFiltrados(documentosValidos);
  };

  useEffect(() => {
    filtrarOpcoesValidas();
  }, [usuarioSelecionado, contaSelecionada, fornecedorSelecionado, documentoSelecionado, chaves]);

  return (
    <div className="mb-2">
      <div className="row">
        <div className="col-md-12">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione uma conta"
            name="conta"
            options={contasFiltradas.length > 0 ? contasFiltradas : formatOptions(contas)}
            onChange={(selectedOption) => {
              setContaSelecionada(selectedOption ? selectedOption.value : null);
              handleChange("conta")(selectedOption);
            }}
            isClearable={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione um usuário digitador"
            name="usuarioDigitador"
            options={usuariosFiltrados.length > 0 ? usuariosFiltrados : formatOptions(usuarios)}
            onChange={(selectedOption) => {
              setUsuarioSelecionado(selectedOption ? selectedOption.value : null);
              handleChange("usuarioDigitador")(selectedOption);
            }}
            isClearable={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione um fornecedor"
            name="fornecedor"
            options={fornecedoresFiltrados.length > 0 ? fornecedoresFiltrados : formatOptions(fornecedores)}
            onChange={(selectedOption) => {
              setFornecedorSelecionado(selectedOption ? selectedOption.value : null);
              handleChange("fornecedor")(selectedOption);
            }}
            isClearable={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione um tipo de documento"
            name="documento"
            options={documentosFiltrados.length > 0 ? documentosFiltrados : formatOptions(documentos)}
            onChange={(selectedOption) => {
              setDocumentoSelecionado(selectedOption ? selectedOption.value : null);
              handleChange("documento")(selectedOption);
            }}
            isClearable={true}
          />
        </div>
      </div>
    </div>
  );
}

export default FiltrosChaves;
